import React, { useState } from 'react'
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { navigate } from '@reach/router';
import { StaticImage } from 'gatsby-plugin-image'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import Seo from '../components/seo';
import { useTranslation } from 'react-i18next';

const containerStyle = {
    width: '400px',
    height: '400px'
};

const center = {
    lat: -3.745,
    lng: -38.523
};

const ContactPage = (location) => {
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const { t } = useTranslation();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const handlePost = (event) => {
        let myForm = document.getElementById('myform');
        let formData = new FormData(myForm)

        fetch('/', {
            method: 'POST',
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: new URLSearchParams(formData).toString()
        })
            .then(
                navigate("/thank-you")
            )
            .catch((error) =>
                setShowErrorMessage(true)
            )
    }

    const isLoaded = false;
    // const { isLoaded } = useJsApiLoader({
    //     id: 'google-map-script',
    //     googleMapsApiKey: "AIzaSyAqpTTpJM4rbih_EJoLQTTeBcvkpmxn4yw"
    // })

    const [map, setMap] = React.useState(null)

    const onLoad = React.useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds();
        map.fitBounds(bounds);
        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])


    return (
        <Layout location={location}>
            <Seo
                title={`${t('Contact')}`}
                description={t('ContactMetaDescription')}
            />
            {/* <div className="site-width page-title">
                <h1 className="">Contact</h1>
            </div> */}
            <div className="c-block c-block--first" style={{ backgroundColor: 'white' }}>
                <div className="site-width contact-us-page" style={{ flexWrap: 'wrap' }}>
                    <section className="c-contact-us__form">
                        <h2 className="c-block__title" style={{ fontSize: '24px' }}>{t('WeLikeToHearIt')}</h2>
                        <p>{t('ContactUsIntro')}</p>
                        <form name="contact" method="POST" id="myform" onSubmit={handleSubmit(handlePost)} data-netlify="true">
                            <input type="hidden" name="bot-field" />
                            <input type="hidden" name="form-name" value="contact" />
                            <fieldset>
                                <div className="form-group">
                                    <label for="firstName">{t('FirstName')} *</label>
                                    <input className={classNames({ error: errors.firstName })} type="text" {...register('firstName', { required: true })} />
                                    {errors.firstName && <p className="form-input-validation">{t('FirstNameRequired')}</p>}
                                </div>
                                <div className="form-group">
                                    <label for="firstName">{t('EmailAddress')} *</label>
                                    <input className={classNames({ error: errors.emailAddress })} type="email" {...register('emailAddress', { required: true })} />
                                    {errors.emailAddress && <p className="form-input-validation">{t('EmailAddressRequired')}</p>}
                                </div>
                                <div className="form-group">
                                    <label for="firstName">{t('Message')} *</label>
                                    <textarea className={classNames({ error: errors.message })} {...register('message', { required: true })} />
                                    {errors.message && <p className="form-input-validation">{t('MesssageRequired')}</p>}
                                </div>
                            </fieldset>
                            {
                                showErrorMessage &&
                                <div>
                                    Er ging iets mis bij het verzenden van het formulier. Probeer het later opnieuw of neem rechtstreeks contact met ons op via <a href="tel:+32472379980">+32 472 37 99 80</a> of <a href="mailto:axel@autohunt.be">axel@autohunt.be</a>
                                </div>
                            }
                            <input type="submit" className="main-cta" value={t('Send')} />
                        </form>
                    </section>
                    <section className="c-contact-info__details">
                        <h2 className="c-block__title" style={{ fontSize: '24px' }}>{t('AxelIsAtYourDisposal')}</h2>
                        <StaticImage
                            width={150}
                            height={150}
                            src="../images/Axel Dejager.jpeg" alt="Axel Dejager" />
                        <p>Axel Dejager</p>
                        <div className="contact-info__phone">
                            <a href="">axel@autohunt.be</a><br />
                            <a href="">+32 472 37 99 80</a>
                        </div>
                    </section>
                </div>
            </div>
            <div className="c-block">
                <div className="site-width">
                    <h2 className="c-block__title" style={{ fontSize: '24px' }}>{t('VisitUs')}</h2>
                    <div className="contact-info__address">
                        {t('OurAddress')}
                    </div>
                    <div className="contact-info__hours">
                        {t('OpeningHours')}
                    </div>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10057.88919319782!2d3.2622723!3d50.933478!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x652b0ed585e8618a!2sAutoHunt!5e0!3m2!1sen!2sbe!4v1646858318873!5m2!1sen!2sbe" width="600" height="450" style={{ border: 0, maxWidth: '600px' }} allowfullscreen="" loading="lazy"></iframe>
                    {/* {
                        isLoaded ? (
                            <GoogleMap
                                mapContainerStyle={containerStyle}
                                center={center}
                                zoom={10}
                                onLoad={onLoad}
                                onUnmount={onUnmount}
                            >
                                <></>
                            </GoogleMap>
                        ) : <></>
                    } */}
                </div>
            </div>
        </Layout>
    )
}

export default ContactPage

export const query = graphql`
query ($language: String!){
        locales: allLocale(filter: {language: {eq: $language}}) {
          edges {
            node {
              ns
              data
              language
            }
          }
        }
      }
`